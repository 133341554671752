import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="spacer" style={{ marginTop: '20px' }} />
      <section className="footercontainer">
        <section className="footer">
          <div className="footer__left">
            <div id="f1" style={{ fontFamily: 'Courier New' }}>
              by&nbsp;
              <a
                style={{ fontFamily: 'Courier New', fontWeight: 400 }}
                href="http://ivanolita.com/"
              >
                IVAN OLITA
              </a>
            </div>
          </div>

          <div className="footer__right">
            <div id="f2">
              <a href="mailto:info@theolita.guide">
                <AiOutlineMail />
              </a>
            </div>

            <div id="f3">
              <a href="https://www.instagram.com/theolita.guide/">
                <FaInstagram />
              </a>
            </div>

            <div id="f4" style={{ fontFamily: 'Courier New', fontWeight: 400 }}>
              <span
                style={{
                  fontFamily: 'Courier New',
                  fontWeight: 400,
                  marginRight: '3px',
                }}
              >
                &copy;
              </span>
              {currentYear}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Footer;
