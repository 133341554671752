import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';
import { navigate } from 'gatsby';

function ThankYouPage() {
  useEffect(() => {
    localStorage.setItem('theolitaguide__signedup', 'true');
  }, []);

  return (
    <Wrapper>
      <div className="inner__column">
        {/* <meta
        http-equiv="refresh"
        content="3;URL='https://theolitaguide.netlify.app'"
        /> */}

        <div id="thanks">
          <p>
            Thank you, we will make sure to send you new guides as soon as they
            launch!
          </p>
        </div>

        <button className="button" onClick={() => navigate('/')}>
          GO BACK
        </button>
      </div>

      <div className="wrapper__footer">
        <Footer />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  font-family: 'Arial', sans-serif;

  #thanks {
    font-family: 'Courier New', Courier, monospace;
    width: 100%;
    font-size: 24px;
    text-align: left;

    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }

  position: relative;

  height: 100vh;

  .wrapper__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .inner__column {
    padding: 8% 0 0;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: transparent;
    max-width: 1200px;
    margin: auto;
    text-align: center;
    justify-content: flex-start;
    position: relative;
    min-height: calc(84vh - 48px);
    flex-grow: 1;

    @media screen and (max-width: 600px) {
      padding: 8% 8% 0;
    }

    .form__row {
      display: flex;
      flex-direction: row;
      width: 100%;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        flex-wrap: wrap;
      }
    }
  }

  .button {
    cursor: pointer;
    text-decoration: underline;
    color: #fff;
    border: 0;
    background: transparent;
    position: absolute;
    bottom: 50%;
    left: 0;
    font-size: 2rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 400;
    font-size: 24px;

    @media screen and (max-width: 600px) {
      position: initial;
      margin-top: 40px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export default ThankYouPage;
